<template>
  <div>
    <div class="row align-items-stretch">
      <div class="section__acceleration-description mb-2 col">
        <div class="title-with-icon mb-4">
          <div class="title-with-icon__icon title-with-icon__icon_acceleration"></div>
          <div class="title-with-icon__title">Студенческий турнир по международным переговорам</div>
        </div>
        <div class="section__acceleration-description-text">
          <p>С целью популяризации экспортной деятельности среди молодежной аудитории Школа экспорта РЭЦ на регулярной основе реализует широкий спектр мероприятий, в том числе с участием профильных ВУЗов.</p>
          <p>Один из форматов - студенческие командные турниры по международным переговорам (на площадках крупных международных форумов: ПМЭФ, «Сделано в России»). Цель - развитие практических знаний и навыков по основам проведения переговоров с иностранным контрагентом с учетом кросс-культурных особенностей партнеров.</p>
        </div>
        <h2 class="mb-4 mt-4">
          Ключевые цели
        </h2>
        <div class="section__acceleration-description-text mb-4">
          22 октября 2022 года Школа экспорта РЭЦ в рамках ежегодного Международного экспортного форума «Сделано в России» планирует проведение суперлиги студенческого турнира «Эффективные переговоры с иностранным партнером» в очном формате (г. Москва).
        </div>
        <h2 class="mb-4 mt-4">
          А призы будут?
        </h2>
        <div class="section__acceleration-description-text mb-4">
          За победу будут бороться победители предыдущих турниров и команды, успешно прошедшие квалификационный отбор. Все участники турнира получат памятные сувениры от Школы экспорта РЭЦ, а команда-победитель - возможность в 2023 году пройти обучение по очной программе обучения Школы экспорта РЭЦ на безвозмездной основе.
        </div>
      </div>
      <div class="col-md-4 mb-1 mb-md-0 section__acceleration-tag">
        <div class="container-shadow p-24 fill-white">
          <div class="section__acceleration-tag-img mb-24">
            <img src="/pic/studconcurs.jpg" class="object-fit-cover"/>
          </div>
          <div class="section__acceleration-tag-title">
            Если у вас остались вопросы по участию в турнире - спрашивайте у нас!
          </div>
          <a href="mailto:komarova@exportcenter.ru" class="link-target"><span>Задать вопрос</span></a>
        </div>
      </div>
    </div>
    <div class="row align-items-stretch">
      <div class="section__acceleration-description col">
        <div class="section__acceleration-content">
          <h2 class="mb-5">Приглашаем команды ВУЗов-партнеров принять участие в мероприятии, а для этого необходимо:</h2>
          <div class="section__acceleration-content-steps">
            <div class="section__acceleration-content-step">
              <div class="section__acceleration-content-step-wrapper">
                <h2>Шаг 1</h2>
                <div class="item-content">
                  Подать заявку от ВУЗа-партнера до 20.09.2022 (1 ВУЗ - 1 команда, до 4 участников) *
                </div>
              </div>
            </div>
            <div class="section__acceleration-content-step">
              <div class="section__acceleration-content-step-wrapper">
                <h2>Шаг 2</h2>
                <div class="item-content">
                  Пройти дистанционный квалификационный отбор (топ-8 команд)
                </div>
              </div>
            </div>
            <div class="section__acceleration-content-step">
              <div class="section__acceleration-content-step-wrapper">
                <h2>Шаг 3</h2>
                <div class="item-content">
                  Принять очное участие в полуфинале и финале (г. Москва)
                </div>
              </div>
            </div>
            <div class="section__acceleration-description-text mt-5 ml-5">
              <p>* студенты 3-4 курсов бакалавриата и/или магистратуры по профильным направлениям подготовки, связанным с ВЭД: экономика, менеджмент, таможенное дело, торговое дело и пр.</p>
              <p>* рабочий язык турнира – русский</p>
              <p>* участие в турнире бесплатное (трансфер до места проведения турнира, проживание, питание и другие расходы обеспечиваются за счет средств участников)</p>
              <p>По всем вопросам, связанным с участием в турнире, просьба писать на <a href="mailto:info@exportedu.ru">info@exportedu.ru</a>.</p>
            </div>
            <div class="mt-5">
              <a href="https://exportedu.ru/forms/konkurs" class="link-target ml-0">
                <span>подать заявку</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Concurs"
}
</script>

<style scoped>

</style>