<template>
  <div>
    <div class="row align-items-stretch mb-2">
      <div class="col section__education-description">
        <div class="section__education-description-text">
          <p>
            Школа экспорта активно развивает партнерские проекты с ВУЗами, нацеленные на студентов магистратуры и бакалавриата.
          </p>
        </div>
      </div>
    </div>
    <stud-program :program-id="48" />
    <stud-program :program-id="28" />
  </div>
</template>

<script>
import StudProgram from "@/components/StudProgram.vue";
export default {
  name: "Stud",
  components: {StudProgram}
}
</script>

<style scoped>

</style>