<template>
  <span v-if="courses && program">
    <div v-for="(course,key) in courses" :key="key" :class="{loading: loading}" class="program-card container-shadow" style="position: relative;">
      <div v-if="programId === 48" class="label-new">
          Новинка
      </div>
      <div class="program-card__grid">
      <div class="program-card__grid-img">
        <img v-if="course.preview" :src="course.preview['500x']"/>
        <img v-else src="/pic/course-img.jpeg"/>
      </div>
      <div class="program-card__grid-data">
        <div class="program-card__grid-date">{{ course.start_date_text }}</div>
        <div class="program-card__grid-title">
          <a :href="`/education/${program.alias}/${course.alias}`">
            {{ course.title }}
          </a>
        </div>
        <div class="program-card__grid-content">
          {{ course.short_desc }}
        </div>
        <div class="program-card__grid-links d-flex align-items-center justify-content-between">
          <div class="mt-1"><a class="btn btn-outline-primary" :href="`/education/${program.alias}/${course.alias}`">Подробнее</a></div>
        </div>
      </div>
      <div class="program-card__grid-info">
        <div class="program-card__grid-info-item" :class="{'pt-5': programId === 48}">
          <div class="program-card__grid-info-label">Стоимость:</div>
          <div class="program-card__grid-info-content">
            {{ course.price }}
          </div>
        </div>
        <div class="program-card__grid-info-item">
          <template v-if="course.tutors.length">
            <div class="program-card__grid-info-label">Авторы:</div>
            <div class="program-card__grid-info-content">
              <template v-if="!showMore[course.id]">
                    <span v-for="(tutor, index) in course.tutors.slice(0, 4)" :key="index">
                        {{ tutor.fullname }}<br/>
                    </span>
              </template>
              <template v-else>
                    <span v-for="(tutor, index) in course.tutors" :key="index">
                        {{ tutor.fullname }}<br/>
                    </span>
              </template>
              <div class="show-more" v-if="course.tutors.length > 4">
                    <span class="href" @click="showMoreTutor(course.id)">
                      {{ showMore[course.id] ? 'Скрыть' : 'Показать ещё' }}
                    </span>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    </div>
  </span>
</template>

<script>
import ApiService from "../../services/api.service";
export default {
  name: "StudProgram",
  data() {
    return {
      showMore: {},
      courses: null,
      program:null
    }
  },
  props: {
    programId: {
      type: Number,
      required: true
    }
  },
  mounted() {
    this.getEvents()
    ApiService.get(`programs/${this.programId}`).then(res => {
      this.program = res.data
    })
  },
  methods: {
    getEvents() {
      this.loading = true
      ApiService.get('courses', {
        params: {
          limit: 15,
          program: this.programId,
          order_by: this.orderBy,
          order_direction: this.direction
        }
      }).then(res => {
        this.loading = false
        this.courses = res.data.courses
      })
    },
    showMoreTutor(id) {
      this.showMore[id] = !this.showMore[id]
      this.getEvents()
    }
  }
}
</script>

<style scoped>

</style>